import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { useSlack } from '../components/hooks'
import { BB_REGISTER_TYPEFORM_URL } from '../utils/constants'

const RegisterForm: React.FC<{}> = () => {
  useSlack('Public Registration Form')
  return <ReactTypeformEmbed url={BB_REGISTER_TYPEFORM_URL} />
}

export default RegisterForm
